<template>
    <NuxtLink :to="`/recipes/${recipe.id}`" class="block">
        <figure>
            <div class="rounded overflow-hidden">
                <nuxt-img sizes="xs:100vw sm:50vw md:33vw lg:25vw" width="512" height="320"
                    class="animatable-img w-full h-40 object-cover" format="webp" :src="recipe.image.url" loading="lazy"
                    placeholder :title="recipe.image.title" :alt="recipe.image.alt" />
            </div>
            <figcaption class="mt-2 space-y-2">
                <div
                    class="text-lg font-bold leading-tight line-clamp-2 overflow-hidden text-gray-800 dark:text-gray-200">
                    <span v-html="recipe.name"></span>
                </div>
                <p class="text-sm text-gray-600 dark:text-gray-400">{{ formatDuration(recipe.totalTime) }}</p>
            </figcaption>
        </figure>
    </NuxtLink>
</template>

<script>
export default {
    props: {
        recipe: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
a {
    @apply text-black dark:text-white no-underline;
}
</style>