export function formatDuration(duration) {
    if (duration === null || duration === undefined) {
        return ''
    }

    const match = duration.match(/PT(\d+H)?(\d+M)?/);

    let formatted = '';
    if (match) {
        const hours = match[1] ? parseInt(match[1]) : 0;
        const minutes = match[2] ? parseInt(match[2]) : 0;

        if (hours > 0) {
            formatted += hours + 'h';
        }
        if (minutes > 0) {
            if (formatted.length > 0) formatted += ' ';
            formatted += minutes + ' min';
        }
    }

    return formatted || '0 min';
}